import axios from 'axios';
import Bowser from 'bowser';
import EXIF from 'exif-js';
import * as filestack from 'filestack-js';
import invariant from 'invariant';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { UploadError } from 'src/errors/documents/file-upload-error';
import logger from 'src/logger';
import api from 'src/api';
import UploadKind from 'src/api/public/documents';
import { isGlideMobileApp } from 'src/utils/browsers';
import { noExtension } from 'src/utils/filenames';

const FILESTACK_KEY = window.Glide.FILESTACK_KEY;

let client;
const getClient = () => {
  if (!client) {
    invariant(
      !window.Glide?.isEmbedded,
      'Cannot initiate filestack on Glide embedded.'
    );
    client = filestack.init(FILESTACK_KEY); // TODO by env
  }
  return client;
};

const FILESERVICE_BASE_URL = window.Glide.FILESERVICE;
const FILESERVICE_FILES_ENDPOINT = `${FILESERVICE_BASE_URL}/files`;
const FILESERVICE_CD_FILES_ENDPOINT = `${FILESERVICE_BASE_URL}/cd/files`;

const storageOptions = {
  access: 'public',
  location: 's3',
};

const defaultReducedResolution = {
  MAX_WIDTH: 1280,
  MAX_HEIGHT: 720,
};

const defaultPickOptions = {
  accept: ['.pdf'],
  maxFiles: 200,
  storeTo: storageOptions,
  fromSources: ['local_file_system', 'googledrive', 'url'],
};

// Included low performance polyfill to support edge. Ref: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value(callback, type, quality) {
      const dataURL = this.toDataURL(type, quality).split(',')[1];
      setTimeout(() => {
        const binStr = atob(dataURL);
        const len = binStr.length;
        const arr = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        callback(
          new Blob([arr], {
            type: type || 'image/png',
          })
        );
      });
    },
  });
}

const sanitizeURI = (url) => {
  // Custom sanitization performed over url to avoid chars like '(', ')' or "'" that do not show if included
  //  in a background-image: url(...) CSS style
  const clearChars = ["'", '(', ')'];

  return clearChars.reduce(
    (cleanUrl, replaceChar) =>
      cleanUrl.replace(
        new RegExp(`\\${replaceChar}`, 'g'),
        `%${replaceChar.charCodeAt(0).toString(16)}`
      ),
    url || ''
  );
};

export function fileResponseSummary(fileResponse) {
  const { filename, container, key, url, size, handle } = fileResponse;
  // Prepare the s3 url for file.
  const urlEncodedKey = sanitizeURI(encodeURIComponent(key));
  const s3Url = `https://${container}.s3.amazonaws.com/${urlEncodedKey}`;

  return {
    filename,
    handle,
    url: s3Url,
    filestackUrl: url,
    byteSize: size,
  };
}

function getPDFConversionUrl(url, handle, filename) {
  // using file picker api to covert other file types the file to pdf
  // https://www.filestack.com/docs/document-transformations
  // resizing to width so it fits A4 page size
  if (
    filename.endsWith('.docx') ||
    filename.endsWith('.doc') ||
    filename.endsWith('.txt')
  ) {
    return url.replace(handle, `/output=format:pdf/${handle}`);
  }
  return url.replace(
    handle,
    `resize=w:600/rotate=deg:exif,exif:true/output=format:pdf/${handle}`
  );
}

export async function convertFileToPDF(file) {
  const { filestackUrl, handle, filename } = file;

  const conversionUrl = getPDFConversionUrl(filestackUrl, handle, filename);
  // Make filestack convert the uploaded file to pdf and store it.
  const conversionOptions = merge(cloneDeep(storageOptions), {
    filename: `${noExtension(filename)}.pdf`,
  });
  const result = await getClient().storeURL(conversionUrl, conversionOptions);

  return fileResponseSummary(result);
}

async function prepareFile(file, convertFilesToPDF) {
  let ret = fileResponseSummary(file);

  if (convertFilesToPDF && file.mimetype !== 'application/pdf') {
    ret = convertFileToPDF(ret);
  }

  return ret;
}

function getFileserviceUrl(asPDF, useCdEndpoint) {
  const url = useCdEndpoint
    ? FILESERVICE_CD_FILES_ENDPOINT
    : FILESERVICE_FILES_ENDPOINT;

  return asPDF ? `${url}:pdf` : url;
}

function getKindOfProxyUpload(isPdf) {
  if (isPdf) {
    return UploadKind.AsPdf;
  }
  return UploadKind.Default;
}

export async function upload(
  f,
  fileName,
  asPDF = false,
  setUploadStatus = () => {},
  features
) {
  if (fileName) {
    storageOptions.filename = fileName;
  }
  const formData = new FormData();
  formData.append('file', f, fileName || f.name);

  const uploadPromise = getUploadPromise(
    features,
    asPDF,
    formData,
    setUploadStatus
  );
  uploadPromise.catch((error) => {
    logger.info('Error while uploading file');
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      logger.info({
        msg: 'error.response',
        data: error.response.data,
        status: error.response.status,
        headers: error.response.headers,
      });
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      logger.info({
        msg: 'error.request',
        request: error.request,
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      logger.info({
        msg: 'error.others',
        message: error.message,
      });
    }
    logger.info(error.config);
    logger.error(error);
    throw error;
  });
  /* Successful response from fileservice but error set to true indicates
   invalid PDF */
  const fileData = (await uploadPromise).data[0];
  if (fileData.error) {
    throw UploadError.from(fileData) ?? UploadError.getUnknownError();
  }
  return Object.assign(fileData, {
    byteSize: fileData.size,
    filename: fileData.newFilename || fileData.filename,
  });
}

function getUploadPromise(
  features,
  asPDF,
  formData,
  setUploadStatus = () => {}
) {
  const onUploadProgress = (progress) => {
    const percentCompleted = Math.round(
      (progress.loaded * 100) / progress.total
    );
    setUploadStatus(percentCompleted);
  };

  // TODO after updating instances calling Upload we must change: features?.docsUseProxyDownload for features.docsUseProxyDownload
  //* for reference go to task: https://compass-tech.atlassian.net/browse/TJ-40642
  if (features?.docsUseProxyDownload) {
    const kind = getKindOfProxyUpload(asPDF);

    return api.documents.uploadFileProxied(kind, formData, {
      onUploadProgress,
    });
  }
  const url = getFileserviceUrl(
    asPDF,
    features?.isTMDocsUploadRestrictionsEnabled
  );

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
}

export default async function pickFile(options = {}, onUploadFinish) {
  const convertFilesToPDF = options.convertFilesToPDF || false;
  const pickOptions = omit(
    {
      ...cloneDeep(defaultPickOptions),
      ...(options || {}),
    },
    ['convertFilesToPDF']
  );
  const result = new Promise((resolve, reject) => {
    pickOptions.onUploadDone = async (res) => {
      if (res.filesFailed.length) {
        const message = 'Failed to upload files with filestack';
        reject(new Error(message));
      }

      if (onUploadFinish) {
        await onUploadFinish();
      }

      if (pickOptions.maxFiles > 1) {
        resolve(
          Promise.all(
            res.filesUploaded.map((file) =>
              prepareFile(file, convertFilesToPDF)
            )
          )
        );
      } else {
        resolve(prepareFile(res.filesUploaded[0], convertFilesToPDF));
      }
    };
    pickOptions.onCancel = () => {
      resolve(undefined);
    };
    getClient().picker(pickOptions).open();
  });
  return result;
}

function computeResolution(srcResolution, newResolution) {
  const maxResolution = newResolution || defaultReducedResolution;
  const maxWidth = maxResolution.MAX_WIDTH;
  const maxHeight = maxResolution.MAX_HEIGHT;
  const srcWidth = srcResolution.width;
  const srcHeight = srcResolution.height;

  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return {
    width: srcWidth * ratio,
    height: srcHeight * ratio,
  };
}

export async function reduceResolution(imageFile, newResolution) {
  if (['image/heic', 'image/heif'].includes(imageFile.type)) {
    return imageFile;
  }
  const temporaryFileReader = new FileReader();
  temporaryFileReader.readAsDataURL(imageFile);
  const browser = Bowser.getParser(window.navigator.userAgent);

  const rendersWithExif =
    browser.satisfies({
      chrome: '>=81',
      safari: '>=13.1',
    }) || isGlideMobileApp();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem reducing input file resolution.'));
    };

    temporaryFileReader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        EXIF.getData(imageFile, function cb() {
          let srcOrientation = EXIF.getTag(this, 'Orientation');
          // set proper canvas dimensions before transform & export
          const srcResolution = {
            width: img.width,
            height: img.height,
          };
          const resolution = computeResolution(srcResolution, newResolution);

          if (rendersWithExif) {
            srcOrientation = 0;
          }

          if (srcOrientation > 4 && srcOrientation < 9) {
            canvas.width = resolution.height;
            canvas.height = resolution.width;
          } else {
            canvas.width = resolution.width;
            canvas.height = resolution.height;
          }

          // transform context before drawing image
          switch (srcOrientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, resolution.width, 0);
              break;
            case 3:
              ctx.transform(-1, 0, 0, -1, resolution.width, resolution.height);
              break;
            case 4:
              ctx.transform(1, 0, 0, -1, 0, resolution.height);
              break;
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              ctx.transform(0, 1, -1, 0, resolution.height, 0);
              break;
            case 7:
              ctx.transform(0, -1, -1, 0, resolution.height, resolution.width);
              break;
            case 8:
              ctx.transform(0, -1, 1, 0, 0, resolution.width);
              break;
            default:
              break;
          }

          // draw image
          ctx.drawImage(img, 0, 0, resolution.width, resolution.height);
          ctx.canvas.toBlob(
            async (blob) => {
              const resizedFile = new Blob([blob], {
                type: 'image/jpeg',
              });
              resolve(resizedFile);
            },
            'image/jpeg',
            0.9
          );
        });
      };
    };
  });
}
